<template>
  <v-data-table
    :loading="!arses"
    :items="arses || []"
    :headers="headers"
    :items-per-page="5"
  >
    <template v-slot:[`item.crDate`]="{ item: { crDate } }">
      {{ crDate | moment('yyyy-MM-DD HH:mm:ss') }}
    </template>
    <template v-slot:[`item.upDate`]="{ item: { upDate } }">
      {{ upDate | moment('yyyy-MM-DD HH:mm:ss') }}
    </template>
  </v-data-table>
</template>

<script>
import collectionAccountMixin from './collectionAccountMixin.js'

export default {
  mixins: [collectionAccountMixin],
  data() {
    return {
      headers: [
        { text: 'idx', value: 'idx', sortable: false },
        { text: '이름', value: 'name', sortable: false, width: 100 },
        { text: '생년월일', value: 'birthday', sortable: false, width: 120 },
        {
          text: '휴대폰번호',
          value: 'phoneNumber',
          sortable: false,
          width: 150,
        },
        { text: '결과코드', value: 'retCode', sortable: false },
        { text: '결과메세지', value: 'retMsg', sortable: false, width: 200 },
        // { text: '은행코드', value: 'bankCode', sortable: false },
        { text: '은행', value: 'bankName', sortable: false, width: 80 },
        { text: '계좌번호', value: 'bankAccount', sortable: false },
        { text: '요청시간', value: 'crDate', sortable: false, width: 180 },
        { text: '수정시간', value: 'upDate', sortable: false, width: 180 },
      ],
    }
  },
  async created() {
    await this.getBnkAccountarses()
  },
}
</script>

<style></style>
